import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Spacing from '../../components/partials/Spacing'
import AboveFooterSection from '../../components/AboveFooterSection'

import { StaticImage } from 'gatsby-plugin-image'
const playtika = () => {
  const data = useStaticQuery(graphql`
    query PlaytikaSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "PlaytikaCaseStudy" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="overflow-hidden hero-margin">
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-lg-4 col-xl-5 col-12 justify-content-center align-self-center">
              <div className="mb-3">
                <StaticImage
                  className="w-30 pb-3 px-4"
                  placeholder="tracedSVG"
                  src="../../resources/images/clients/playtika/playtika.png"
                  alt="Playtika Logo"
                />
              </div>
              <div>
                <h1>
                  How Playtika Gains 300 Engineering Hours Per Day with
                  Lenses.io
                </h1>
                <div>
                  <h2 className="hero-description-dark pt-3 roboto mt-2">
                    Read how this social gaming giant provides data
                    observability over Apache Kafka to accelerate time-to-value
                    of streaming apps.
                  </h2>
                </div>
              </div>

              <div className="roll-button mt-3">
                <a className="text-white bg-red" href="/start/">
                  <span id="heroButton" data-hover="Try free">
                    Try free
                  </span>
                </a>
              </div>
            </div>

            <div className="col-lg-8 col-xl-7 col-12 tablet-margin-top">
              <StaticImage
                className="ml-4"
                placeholder="tracedSVG"
                src="../../resources/images/clients/playtika/playtika_hero.png"
                alt="Playtika Hero"
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-md-3 col-sm-12 mobile-margin-bottom mobile-margin-top">
              <div className="footer-color position-sticky rounded casestudy-container-left">
                <div className="px-3 pt-1 pb-4">
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    300
                  </p>
                  <p>
                    <b>working hours</b> gained
                  </p>
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    ~ 15 minutes
                  </p>
                  <p>to solve issues impacting millions of gamers</p>
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    600
                  </p>
                  <p>engineers, developers and analysts</p>
                  <p className="h3-like-dark mb-1 mt-5 primary-text f-18 fw-500">
                    <b>€2 million</b>
                  </p>
                  <p>saved per year</p>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12">
              <div className="casestudy-container-right">
                <p className="p-text">
                  Playtika is one of the leading social mobile gaming companies,
                  with 27+ million active monthly users and a team of more than
                  2.5 thousand developers, storytellers, explorers and
                  data-scientists.
                </p>
                <p className="h3-like-dark mt-4 text-dark">
                  Platform triumphs & challenges
                </p>
                <p className="p-text">
                  Playtika chose <b>Apache Kafka</b> and Kubernetes as the core
                  platform for information exchange between microservices. The
                  performance, wide adoption and scalability were crucial to
                  this decision.
                </p>
                <p className="p-text">
                  Initially, a large collection of Open-Source and in-house
                  built tools were tested to cover some of the gaps in running
                  and operating a realtime data platform. But it was once the
                  architecture was defined that <b>challenges</b> started
                  emerging: each project had to have a separate Kafka and
                  Kubernetes cluster to isolate responsibilities - yet still
                  remain consistent and scalable. How would they have the
                  resources to build and maintain this platform?
                </p>
                <p className="p-text">
                  Running multiple tools in parallel per cluster, would result
                  in huge <b>infrastructure costs</b>, particularly when
                  operating hundreds environments. If you add maintenance and
                  development for scripts and automations, it would not only
                  break the budget, it would require resourcing a team of tech
                  experts dedicated to distributed technologies. This would:
                </p>
                <p className="p-text pl-2">
                  a) Distract engineers from building out the core competency of
                  the company - to deliver the best mobile gaming experience in
                  the world.
                </p>
                <p className="p-text pl-2">
                  b) Beyond engineering, Playtika wanted to provide a polished
                  analytics experience to design, development, marketing and
                  other teams, to generate greater insights with the streaming
                  data powering their mobile experiences. As the lack of
                  observability was slowing down the productivity of all teams,
                  and building the required features in-house was complex and
                  costly, something had to change.
                </p>
                <p className="p-text"></p>

                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-19 quote-line-height">
                    <i>
                      Lenses.io has been a great choice for us, for delivering a
                      polished and production ready platform experience over
                      Apache Kafka across all our games, that significantly
                      increases productivity for hundreds of users. That has
                      enabled us to accelerated the delivery of new value to the
                      market.
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>VP of IT Ops & Engineering at Playtika - Ella Vidra</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>

                <p className="h3-like-dark text-dark">
                  DataOps excellence with Apache Kafka
                </p>
                <p className="p-text">
                  A quick trial with Lenses demonstrated that the{' '}
                  <b>core principles </b>
                  of DataOps can easily be adopted: different roles on each
                  project could now be on the same page. This enhanced
                  collaboration, while empowering users - whether technical or
                  not. Teams observed that everyone was benefiting from data and
                  application visibility and access.
                </p>
                <p className="h5 pt-3 pb-3 p-text">
                  Without Lenses, looking into production issues with Kafka took
                  hours. With Lenses it was a trivial task.
                </p>
                <p className="p-text">
                  Apart from better understanding schemas, topics, data
                  payloads, consumers, producers and applications, data
                  engineers could also identify the{' '}
                  <b>needle in the haystack</b>. That was a huge benefit: tasks
                  that previously took hours to complete, could now be completed
                  in a matter of minutes and investigating issues across
                  production & non- production environments became trivial.
                </p>

                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-19 quote-line-height">
                    <i>
                      Once we had a production issue simultaneously impacting
                      millions of online gamers. A team had spent hours looking
                      in the wrong place, and we were about to trigger the DR
                      (disaster recovery) environments. A quick look into Lenses
                      revealed the underlying issue and it was resolved within
                      15 minutes.
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>Systems Architect at Playtika - Ivan Vasyliev</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>

                <p className="p-text">
                  Lenses{' '}
                  <a href="/usecases/apache-kafka-security/">
                    data security and privacy{' '}
                  </a>{' '}
                  checked off another key element of Playtika’s core
                  requirements. A complete solution for observability,
                  discovery, monitoring, alerting and security, meant that the
                  company could avoid running tens of open- source and in-house
                  tools and instead chose to collaborate with Lenses.
                </p>
                <p className="p-text">
                  The value and <b>return on investment</b> was easily
                  demonstrable to management. Today, hundreds of people across
                  engineering and operations, use Lenses for observability and
                  discovery, and to investigate and inspect Kafka through a
                  clear, secure and centralized environment every day. They
                  previously had to use dozens of different tools and run
                  commands. Can you begin to imagine how much time, money and
                  stress this saves?
                </p>
                <hr />
                <div>
                  <p className="p-text pt-4">
                    In addition to DataOps excellence, less infrastructure cost,
                    and strong monitoring and security, Playtika estimates the
                    additional <b>productivity</b> is 30 minutes per day. That
                    may not sound like much, but when you consider there are
                    <span className="primary-text">
                      <b> 600</b>
                    </span>{' '}
                    users across engineering and operations, that’s over
                    <span className="primary-text">
                      <b> 300 working hours </b>
                    </span>{' '}
                    gained per day.
                  </p>
                  <p className="p-text">
                    The above amounts to{' '}
                    <span className="primary-text">
                      <b>€2 million</b>
                    </span>{' '}
                    saved per year.
                  </p>
                  <p className="p-text">
                    Now that’s a Return-on-Investment everyone is happy about.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Ready for operational excellence?'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default playtika
